import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { message } from "ant-design-vue";
import { globalStorage } from "@/utils/storage/base";
import { getCookie, TokenKey, loginJump } from "../../utils";
export default defineComponent({
    name: "LoginSelectCorp",
    data() {
        return {
            jwtToken: "",
            corporations: [],
        };
    },
    methods: {
        ...mapActions(["loginWithCorpAction"]),
        handleLogin(corp) {
            if (corp.corpcode && this.jwtToken) {
                this.loginWithCorpAction({
                    enginecode: corp.corpcode,
                    jwt: this.jwtToken,
                })
                    .then(() => {
                    // window.location.replace('http://localhost:5500/home.html');
                    const config = window.globalConfig;
                    const nowTime = new Date().getTime();
                    if (config.redirectUrl && config.redirectType === "1") {
                        // 需要跳转模板中心
                        const url = `${decodeURIComponent(config.redirectUrl)}/index.html#/home?h3_token=${getCookie(TokenKey)}&t=${nowTime}`;
                        window.location.replace(url);
                    }
                    else {
                        const flag = loginJump(getCookie(TokenKey));
                        if (flag)
                            return;
                        window.location.replace(`${window.location.origin}/home.html?t=${nowTime}${corp.corpcode ? `&enginecode=${corp.corpcode}` : ``}`);
                    }
                })
                    .catch((errmsg) => {
                    message.error(errmsg || "系统异常");
                    this.$router.push({
                        name: "Login",
                    });
                })
                    .finally(() => {
                    globalStorage.removeFormLocal("select_token");
                    globalStorage.removeFormLocal("select_corps");
                });
            }
        },
    },
    mounted() {
        const corps = globalStorage.getFormLocal("select_corps");
        this.jwtToken = globalStorage.getFormLocal("select_token");
        this.corporations = (corps && JSON.parse(corps)) || [];
    },
});
